.button-border-top {
  border-top-style: solid;
  border-top-width: 2px;
}

.button-border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.input-range-reset {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0; /* Firefox */
}

.lh-2 {
  line-height: 2rem;
}

.pointer-events-none {
  pointer-events: none;
}

.transform-origin-left {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.transform-center-y {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flex-grow-0 {
  -webkit-flex-grow: 0;
          flex-grow: 0;
}

.ma-auto {
  margin: auto;
}

.animation-opacity-pulse {
  -webkit-animation-duration: 350ms;
          animation-duration: 350ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
          animation-name: pulse;
}

@-webkit-keyframes pulse {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes pulse {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.5;
  }
}

